import Vue from 'vue';
import Router from 'vue-router';

import BankingRoutes from '@/views/Banking/routes';
import techRoutes from '@/views/Tech/routes';
import brandRoutes from '@/views/Brand/routes';

import { getCookie, getCache, removeCache, removeCookie, setCache, hasCache } from '@/services/cache';
import api from '@/services/api';

Vue.use(Router);

const router = new Router({
  linkExactActiveClass: "active",
  mode: 'history',
  routes: [],
  scrollBehavior: to => {
    if (to.hash)
      return { selector: to.hash };
    else
      return { x: 0, y: 0 };
  }
});


router.addRoutes(
  BankingRoutes
);

router.addRoutes(
  techRoutes
);

router.addRoutes(
  brandRoutes
);


if (process.env.NODE_ENV == 'development') {
  router.addRoute(
    {
      path: "/exemplos",
      name: "components",
      components: {
        header: () => import('@/views/template/layout/AppHeader.vue'),
        default: () => import('@/views/template/Components'),
        footer: () => import('@/views/template/layout/AppFooter.vue')
      }
    },
  );
}


router.addRoutes([
  {
    path: "/",
    alias: ['/inicio', '/home', '/index'],
    name: "landing",
    components: {
      header: () => import('@/components/HeaderNavigation.vue'),
      default: () => import('@/views/Home.vue'),
      footer: () => import('@/components/FooterNavigation.vue')
    },
    meta: {
      title: 'Bairon',
      description: 'Bairon Empresa de tecnologia e soluções em meios de pagamento.',
      siteName: 'Bairon',
      indexOnGoogle: true
    }
  },
  {
    path: "/redirect-payment/:siteId/:paymentId",
    name: "redirectPayment",
    components: {
      default: () => import('@/views/RedirectPayment.vue')
    },
    meta: {
      title: 'Bairon - Redirecionando pagamento',
      description: 'Bairon Empresa de tecnologia e soluções em meios de pagamento.',
      siteName: 'Bairon',
      indexOnGoogle: false
    }
  },
  {
    path: "**",
    alias: '*',
    name: "Page404",
    components: {
      header: () => import('@/components/HeaderNavigation.vue'),
      default: () => import('@/views/404.vue'),
      footer: () => import('@/components/FooterNavigation.vue')
    },
    meta: {
      title: 'Bairon - Página não encontrada',
      description: 'Bairon Empresa de tecnologia e soluções em meios de pagamento.',
      siteName: 'Bairon',
      indexOnGoogle: false
    }
  }
]
);


async function getUserData() {
  if (hasCache('user'))
    return { success: true, ...JSON.parse(getCache('user')) };
  else
    await api.get(`/userCustomer`)
      .then(({ data }) => {
        setCache('user', JSON.stringify(data));
        return { success: true, ...data };
      })
      .catch((err) => {
        removeCookie('token');
        return { success: false };
      });
}

router.beforeEach(async (to, from, next) => {
  const token = getCookie('token');
  if (!token && hasCache('user')) {
    removeCache('user');
  }

  if (to.meta.requiresAuth) { // verifica se a página precisa de autenticação
    if (token) { //verifica se o usuário tem token
      await getUserData();
      if (!(await getUserData()).success)
        next({ name: to.meta.loginPage });
      else if (to.meta.requiresAdm && (await getUserData()).credential.appId == 0)
        next();
      else if (!to.meta.requiresAdm && (await getUserData()).credential.appId == 0)
        next({ name: 'contabil.adminConnected' });
      else
        next();
    } else
      next({ name: to.meta.loginPage });

  } else if (to.meta.requiresNoAuth) {
    if (token)
      next({ name: to.meta.homePageLogged });
    else
      next();
  } else
    next();

});


export default router;
